import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SectionHeader } from 'components/molecules';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => ({
  header: {
    position: 'absolute',
    right: 0,
    left: 0,
    margin: 'auto',
    top: '20%',
  },
  root: {
    position: 'relative',
    marginBottom: '5vh',
    background: `url(${require('../../data/images/hero.png')}) no-repeat center center`,
    backgroundSize: 'cover',
    width: '100vw',
    height: '256px',
    marginTop: -1,
  },
}));

const Hero = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <div>
        <SectionHeader
          title={t('blogHeroTitle')}
          subtitle={t('blogHeroSubtitle')}
          fadeUp
          titleVariant="h4"
          subtitleVariant="body1"
          titleProps={{
            style: { color: theme.palette.primary.contrastText },
          }}
          subtitleProps={{
            style: {
              color: theme.palette.primary.contrastText,
              letterSpacing: '1px',
            },
          }}
          className={classes.header}
        />
      </div>
    </div>
  );
};

export default Hero;
