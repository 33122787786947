import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from 'components/Container';
import { Hero, Tabs } from './components';
import { useStaticQuery, graphql } from 'gatsby';
import { SearchBox } from 'components/SearchBox';
import { PostCardList } from 'components/PostCardList';
import { SubscriptionCard } from 'components/SubscriptionCard';
import { SearchResult } from 'components/SearchResult';
import searchBlogs from 'utils/functions/searchBlogs';
import { HOSTNAME_LIVE } from 'utils/constants';
import { DefaultSEO } from 'components/DefaultSEO';
import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';
import filterBlogs from 'utils/functions/keywordFilterBlogs';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  blogSearch: {
    marginTop: '-5rem',
    position: 'relative',
    zIndex: 3,
    paddingY: '0 !important',
  },
}));

const BlogReachView = (): JSX.Element => {
  const i18Context = React.useContext(I18nextContext);
  const lang = i18Context.language;
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query categoryQueryAndMyQuery {
      DJANGO {
        allPost(eBlogStatus: "Published", orderBy: ["from_position"]) {
          edges {
            node {
              highlightsCheck
              createdDate
              engBanner
              chnBanner
              engThumbnail
              chnThumbnail
              engContent
              chnContent
              engCategories {
                catNameEng
                catNameChn
              }
              engTags {
                tagNameEng
                tagNameChn
              }
              engSlug
              engTitle
              chnTitle
              engCaption
              chnCaption
              engKeywords
              chnKeywords
            }
          }
        }
      }
      DJANGO {
        allCategories(orderBy: ["from_position"]) {
          catNameEng
          catNameChn
          engBCategory(eBlogStatus: "Published") {
            edges {
              node {
                id
                engTitle
                chnTitle
                engBanner
                chnBanner
                engThumbnail
                chnThumbnail
                createdDate
                engCategories {
                  catNameEng
                  catNameChn
                }
                engTags {
                  tagNameEng
                  tagNameChn
                }
                engSlug
                engCaption
                chnCaption
                cBlogStatus
              }
            }
          }
        }
      }
    }
  `);

  const [searchView, setSearchView] = useState(false);
  const [allBlogs, setAllBlogs] = useState([]);
  const [highlightedData, setHighlightedData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [seacrhFilterRsult, setSearchFilterResult] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [blogTitles, setBlogTitles] = useState([]);

  useEffect(() => {
    try {
      let titles = data.DJANGO?.allPost?.edges.map(item => {
        return {
          engLabel: item.node.engTitle,
          chnLabel: item.node.chnTitle
            ? item.node.chnTitle
            : item.node.engTitle,
        };
      });
      setAllBlogs(
        data.DJANGO?.allPost?.edges.map(post => {
          if (!post.node.chnBanner) {
            post.node.chnBanner = post.node.engBanner;
          }
          if (!post.node.chnThumbnail) {
            post.node.chnThumbnail = post.node.engThumbnail;
          }
          if (!post.node.chnContent) {
            post.node.chnContent = post.node.engContent;
          }
          if (!post.node.chnTitle) {
            post.node.chnTitle = post.node.engTitle;
          }
          if (!post.node.chnCaption) {
            post.node.chnCaption = post.node.engCaption;
          }
          if (!post.node.chnKeywords) {
            post.node.chnKeywords = post.node.engKeywords;
          }
          return post;
        }),
      );
      let filterHighlighted = data.DJANGO?.allPost?.edges.filter(
        (item: any) =>
          item.node.highlightsCheck === true && item.node.chnContent,
      );
      setHighlightedData(filterHighlighted);
      setBlogTitles(titles);
      let tempCats = data.DJANGO.allCategories
        .filter(i => i.engBCategory.edges.length > 0)
        .map(i => {
          i.engBCategory.edges.forEach(item => {
            if (!item.node.chnBanner) {
              item.node.chnBanner = item.node.engBanner;
            }
            if (!item.node.chnThumbnail) {
              item.node.chnThumbnail = item.node.engThumbnail;
            }
            if (!item.node.chnContent) {
              item.node.chnContent = item.node.engContent;
            }
            if (!item.node.chnTitle) {
              item.node.chnTitle = item.node.engTitle;
            }
            if (!item.node.chnCaption) {
              item.node.chnCaption = item.node.engCaption;
            }
            if (!item.node.chnKeywords) {
              item.node.chnKeywords = item.node.engKeywords;
            }
          });

          return i;
        });
      setCategories(tempCats);
    } catch (error) {
      console.log(error);
    }
  }, [data, lang]);

  const classes = useStyles();

  const searchFilterFunction = (value: string) => {
    let filterResult = searchBlogs(allBlogs, value, lang);
    setSearchValue(value);
    setSearchFilterResult(filterResult);
    setSearchView(true);
  };

  const keywordFilterFunction = (value: string) => {
    let filterResult = filterBlogs(allBlogs, value, lang);
    setSearchValue(value);
    setSearchFilterResult(filterResult);
    setSearchView(true);
  };

  const onBackBlog = () => {
    setSearchView(false);
  };

  const [keywords, setKeywords] = useState([]);

  const fetchKeywordsHere = async () => {
    try {
      const res = await fetch(
        `${HOSTNAME_LIVE}/api/search/get_tag_for_search/`,
      );
      if (res.status === 200) {
        const json = await res.json();
        setKeywords(json.data);
      } else {
        console.log('error fetching search tags');
      }
    } catch (error) {
      console.log('error fetching search tags', error);
    }
  };

  useEffect(() => {
    fetchKeywordsHere();
  }, []);

  return (
    <div className={classes.root}>
      <DefaultSEO
        title="THE CENTER SPACE | Flagship Business Lounge in Hong Kong | 坐落於中環核心的高端服務式辦公室"
        description="The Center is located at the heart of Central, Hong Kong's Central Business District. With a standing of 350 meters, The Center holds the title of Hong Kong’s fifth-highest skyscraper. Located on the 76th floor of the building, one of the highest co-working space in Hong Kong, our space offers panoramic views of Victoria Harbour and Hong Kong Skyline. The Center is also surrounded by major financial, banking, and government headquarters."
      />
      {searchView === false ? (
        <>
          <Hero />
          <Container
            sx={{
              marginTop: { xs: '-8rem', sm: '-9rem', md: '-7rem' },
              position: 'relative',
              zIndex: 3,
              paddingY: '0 !important',
            }}
          >
            <SearchBox
              highlightedData={highlightedData}
              onSearch={searchFilterFunction}
              onFilter={keywordFilterFunction}
              blogTitles={blogTitles}
              keywords={keywords}
            />
          </Container>
        </>
      ) : (
        <Container
          sx={{
            marginTop: '32px',
            position: 'relative',
            zIndex: 3,
            paddingY: '0 !important',
          }}
        >
          <SearchBox
            highlightedData={highlightedData}
            onSearch={searchFilterFunction}
            onFilter={keywordFilterFunction}
            blogTitles={blogTitles}
            keywords={keywords}
          />
        </Container>
      )}

      {searchView === false ? (
        <>
          <Container paddingY={{ xs: 4 }}>
            <PostCardList
              highlightedData={highlightedData}
              title={t('highlightedPostsHeading')}
            />
          </Container>
          <Container paddingY={0}>
            <Tabs categories={categories} />
          </Container>
          <Container>
            <SubscriptionCard type="mainBlog" />
          </Container>
        </>
      ) : (
        <Container>
          <SearchResult
            seacrhFilterRsult={seacrhFilterRsult}
            onBackBlog={onBackBlog}
            searchValue={searchValue}
            highlightedData={highlightedData}
          />
        </Container>
      )}
    </div>
  );
};

export default BlogReachView;
