import React from 'react';
import TabContext from '@material-ui/lab/TabContext';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabList from '@material-ui/lab/TabList';
import CatagoryList from '../CatagoryList';
import { I18nextContext } from 'gatsby-plugin-react-i18next';

const TabCatagory = ({ categories }): JSX.Element => {
  const i18Context = React.useContext(I18nextContext);
  const lang = i18Context.language;
  const [value, setValue] = React.useState('0');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {categories ? (
        <div>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="scrollable auto tabs example"
                  variant="scrollable"
                >
                  {categories.map((item, index) => {
                    return (
                      <Tab
                        label={
                          lang === 'en' ? item.catNameEng : item.catNameChn
                        }
                        value={index.toString()}
                        sx={{ color: '#0F1F38' }}
                        key={index}
                      />
                    );
                  })}
                </TabList>
              </Box>

              {categories.map((item, index) => {
                return (
                  <TabPanel
                    value={index.toString()}
                    style={{
                      padding: 0,
                      paddingTop: '20px',
                    }}
                    key={index}
                  >
                    <CatagoryList data={item} />
                  </TabPanel>
                );
              })}
            </TabContext>
          </Box>
        </div>
      ) : null}
    </>
  );
};

export default TabCatagory;
