import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PostCard } from 'components/PostCard';
import CardMedia from '@mui/material/CardMedia';
import { Link, I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';

const CustomListItem = ({ i, item }) => {
  const i18Context = React.useContext(I18nextContext);
  const lang = i18Context.language;
  

  return (
    <ListItem
      key={i}
      sx={{
        padding: '10px',
        transition: 'all .2s ease-in-out',
        '&:hover': {
          backgroundColor: '#F5F5F5',
          cursor: 'pointer',
        },
        borderRadius: '4px',
      }}
    >
      <Link
        to={`/${item.node.engSlug}`}
        style={{
          textDecoration: `none`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '24px',
            cursor: 'pointer',
          }}
        >
          <CardMedia
            image={
              lang === 'en' ? item.node.engThumbnail : item.node.chnThumbnail
            }
            sx={{
              width: '150px',
              height: '150px',
              borderRadius: '10px',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              borderRadius: '0px 0px 10px 10px',
              flex: 1,
            }}
          >
            <Box sx={{ display: 'flex', marginBottom: '7px' }}>
              {item.node?.engTags.map((item2, index) => {
                return (
                  <div style={{ display: 'flex' }} key={index}>
                    <Typography
                      key={index}
                      align={'left'}
                      fontFamily="Roboto"
                      fontSize={12}
                      sx={{
                        textTransform: 'uppercase',
                      }}
                      fontWeight={500}
                      color={'#B79B75'}
                      letterSpacing="1.5px"
                      lineHeight={'16px'}
                    >
                      {lang === 'en' ? item2.tagNameEng : item2.tagNameChn}
                    </Typography>
                    <span
                      style={{
                        backgroundColor: '#B79B75',
                        height: '2px',
                        width: '2px',
                        alignSelf: 'center',
                        margin: '0px 8px',
                        display:
                          index !== item.node?.engTags.length - 1
                            ? 'inline'
                            : 'none',
                      }}
                    ></span>
                  </div>
                );
              })}
            </Box>
            <Typography
              fontFamily="Roboto"
              fontSize={20}
              align={'left'}
              sx={{
                marginBottom: '7px',
                textTransform: 'capitalize',
              }}
              fontWeight={500}
              color={'#0F1F38'}
              letterSpacing="0.15px"
              lineHeight={'24px'}
              component={'h6'}
            >
              {lang === 'en' ? item.node.engTitle : item.node.chnTitle}
            </Typography>
            <Typography
              align={'left'}
              variant={'body2'}
              color={'#0F1F38'}
              sx={{ marginBottom: '7px' }}
              letterSpacing="0.5px"
              lineHeight={'24px'}
              fontSize={16}
            >
              {lang === 'en' ? item.node.engCaption : item.node.chnCaption}
            </Typography>
            <Typography
              fontFamily="Roboto"
              fontSize={12}
              align={'left'}
              sx={{
                marginBottom: '7px',
                textTransform: 'capitalize',
              }}
              fontWeight={400}
              color={'#B79B75'}
              letterSpacing="0.4px"
              lineHeight={'16px'}
            >
              {item.node.createdDate}
            </Typography>
          </Box>
        </Box>
      </Link>
    </ListItem>
  );
};

const CatagoryList = ({ data }): JSX.Element => {
  const [all, setAll] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const i18Context = React.useContext(I18nextContext);
  const lang = i18Context.language;
  const { t } = useTranslation();

  useEffect(() => {
    let tempData = [];
    tempData = data.engBCategory.edges;
    tempData = tempData.sort((a, b) => {
      let d1 = new Date(a.node.createdDate);
      let d2 = new Date(b.node.createdDate);
      return d2.getTime() - d1.getTime();
    });
    setFilteredData(tempData);
  }, [data, lang]);

  return (
    <div>
      <Grid container spacing={3} sx={{ display: { xs: 'flex', sm: 'none' } }}>
        {filteredData.length > 0 ? (
          all === false ? (
            <>
              {filteredData.slice(0, 4).map((item, i) => (
                <PostCard item={item} key={i} />
              ))}
            </>
          ) : (
            <>
              {filteredData.slice(0, 9).map((item, i) => (
                <PostCard item={item} key={i} />
              ))}
            </>
          )
        ) : (
          <Box display="block" textAlign="center" width="100%" marginTop="20px">
            <Typography
              variant={'body2'}
              color="textPrimary"
              sx={{ opacity: '0.9' }}
              letterSpacing="0.25px"
              textAlign="center"
            >
              {t('categoryListNoBlogs')}
            </Typography>
          </Box>
        )}
      </Grid>
      <List
        style={{ padding: 0 }}
        sx={{ display: { xs: 'none', sm: 'block' } }}
      >
        {filteredData.length > 0 ? (
          all === false ? (
            filteredData
              .slice(0, 4)
              .map((item, i) => <CustomListItem i={i} item={item} key={i} />)
          ) : (
            filteredData
              .slice(0, 9)
              .map((item, i) => <CustomListItem i={i} item={item} key={i} />)
          )
        ) : (
          <Box>
            <Typography
              variant={'body2'}
              color="textPrimary"
              sx={{ opacity: '0.9' }}
              letterSpacing="0.25px"
              textAlign="center"
            >
              {t('categoryListNoBlogs')}
            </Typography>
          </Box>
        )}
      </List>
      <Box
        sx={{
          display: data?.engBCategory.edges.length > 5 ? 'flex' : 'none',
          justifyContent: 'center',
          marginTop: { xs: '10px', md: 0 },
        }}
      >
        {filteredData ? (
          <>
            <Button
              sx={{
                color: '#B79B75',
                fontSize: '20px',
                letterSpacing: '0.15px',
              }}
              onClick={() => {
                setAll(!all);
              }}
              endIcon={
                all ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
            >
              {all ? `See less` : `See more`}
            </Button>
          </>
        ) : null}
      </Box>
    </div>
  );
};

export default CatagoryList;
