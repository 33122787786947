const filterBlogs = (blogData: Array<object>, value: string, lang: string) => {
  const filterResult = [];
  blogData?.map((item: any) => {
    let alreadyPushed = false;
    if (lang === "en") {
      let data2 = item.node.engTags.filter((item: any) => {
        return item.tagNameEng.toLowerCase().includes(value.toLowerCase());
      });
      if (data2.length > 0 && !alreadyPushed) {
        filterResult.push(item);
      }
    } else {
      let data2 = item.node.engTags.filter((item: any) => {
        return item.tagNameChn.toLowerCase().includes(value.toLowerCase());
      });
      if (data2.length > 0 && !alreadyPushed) {
        filterResult.push(item);
      }
    }
  });
  return filterResult;
};

export default filterBlogs;
